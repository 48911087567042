<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="accessPolicy.readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
          
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('access_policy.label').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('access_policy.label').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('access_policy.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('access_policy.field.name')"
                    data-vv-name="access_policy.name"
                    data-vv-delay="500"
                    v-model="accessPolicy.name" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    trim
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'access_policy.name')">
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('access_policy.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          
            <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
              <b-form-group :label="$t('field.identifier')" label-for="identifier">
                <b-input-group>
                  <b-form-input id="identifier" type="text"
                    :data-vv-as="$t('field.identifier')"
                    data-vv-name="accessPolicy.identifier"
                    :maxlength="maxIdentifierLength"
                    v-model="accessPolicy.identifier" 
                    :disabled="isIdentifierReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          
            <b-col v-if="isDescriptionVisible" cols="12" class="pr-0">
              <b-form-group :label="$t('access_policy.field.description')" label-for="description">
                <b-form-textarea id="description" 
                  :placeholder="isDescriptionReadOnly? '' : $t('access_policy.placeholder.description')"
                  v-model="accessPolicy.description"
                  :max-rows="6"
                  :readonly="isDescriptionReadOnly"
                  trim
                  :rows="3"/>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <!-- Permission Header -->
        <PermissionList v-if="isPermissionVisible" 
          :height="456" 
          :permissionIds="permissionIds" 
          :permissionProperties="selectedPermissions" 
          @selected="permissionSelected" 
          :readOnly="isReadOnly" />
          
      </template>
      <template v-slot:modal-footer="{ cancel }">
        <template v-if="!isAccessDenied && (canEdit() || !exists)">
          <b-button size="sm" variant="success" disabled v-if="state.isSubmitting">
            <b-spinner small type="grow" />{{ $t('button.saving') }}
          </b-button>
          <b-button :disabled="disableOk" v-else size="sm" variant="success" @click="modalOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('access_policy.update_users_title')"
        v-model="confirmUpdateUserPermissionsShow"
        :ok-title="$t('button.yes')"
        :cancel-title="$t('button.no')"
        @ok="confirmUpdateUserPermissions"
        @cancel="accessPolicySubmit"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('access_policy.update_users_message') }}
      </div>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox class="data-rules-check" v-model="updateChanges">{{$t('access_policy.update_changes')}}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('title_warning')"
        v-model="confirmRevokingCriticalPermissionShow"
        @ok="confirmRevokingCriticalPermissionOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('statement_risk_losing_account_setting_access') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="ACCESS-POLICY" links="PERMISSION" />
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { strRandom } from '@/helpers';
import { getCustomFieldInfo } from '@/helpers/custom-fields';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { accessPolicyService, accessPolicyLinkPermissionService, userService } from '@/services';
import { removeDeniedProperties } from '@/views/management/script/common';

export default {
  name: 'AccessPolicyModal',
  components: {
    PermissionList: () => import('@/components/Permission/PermissionList'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal')
  },
  props: {
    id:        { type: String,   default: `ACCESS_POLICY_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    permissions: { type: Array, default: null }
  },
  data() {
    return {
      permissionName: 'ACCESS_POLICY',
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      state: {
        editable:           false,
        isSubmitting:       false,
        modalShow:          false,
        historyShow:         false
      },
      accessPolicy: {
        uuId:               null,
        name:               null,
        description:        null,
        identifier:         null,
        readOnly:           false
      },
      originPermissions:    [],
      selectedPermissions:  [],
      userPolicy: {}, // map of each user and their current policies
      confirmUpdateUserPermissionsShow: false,
      userPermissionsNeedUpdate: false,
      isAccessDenied: false,
      userConsentOnRevokingCriticalPermission: false,
      confirmRevokingCriticalPermissionShow: false,
      restrictedRequiredField: null,
      updateChanges: false
    }
  },
  created() {
    this.getModelInfo();
    this.state.editable = (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
    this.fieldValidateUtil = fieldValidateUtil;
    getCustomFieldInfo(this, 'ACCESS-POLICY').catch(e => this.httpAjaxError(e))
    this.original = {
      readOnly: false
    }
    this.originAccessPolicy = null;
    if(this.exists) {
      this.accessPolicyGet(this.id);
      if (this.canView('USER')) {
        this.usersWithPolicyGet(this.id);  
      }
    }
    else if (this.permissions) {
      this.originPermissions = cloneDeep(this.permissions);
      for (const perm of this.originPermissions) {
        if (perm.denyRules && perm.name.endsWith('ADD')) {
          perm.permissionLink = { denyRules: perm.denyRules };
        }
        else if ((perm.denyRules || perm.dataRules) &&
                 (perm.name.endsWith('EDIT') || perm.name.endsWith('VIEW')) &&
                  (perm.name.startsWith('CUSTOMER') ||
                  perm.name.startsWith('DEPARTMENT') ||
                  perm.name.startsWith('PROJECT') ||
                  perm.name.startsWith('STAFF') ||
                  perm.name.startsWith('TAG') ||
                  perm.name.startWith('STAGE'))) {
          perm.permissionLink = { 
            denyRules: perm.denyRules ? perm.denyRules : [],
            dataRules: perm.dataRules ? perm.dataRules : []
          };
        }
      }
      this.selectedPermissions.splice(0, this.selectedPermissions.length, ...cloneDeep(this.originPermissions));
    }
  },
  mounted() {
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originAccessPolicy = null;
  },
  computed: {
    componentId() {
      return `ACCESS_POLICY_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.accessPolicy.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails.length > 0;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'access_policy.name');
    },
    permissionIds() {
      return this.originPermissions.map(i => i.uuId);
    },
    exists() {
      return this.id && !this.id.startsWith('ACCESS_POLICY_NEW_');
    },
    labelTitle() {
      return this.title? this.title : this.$t('access_policy.title_new');
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) && ((!this.exists && this.canAdd(this.permissionName, ['identifier']))
      || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isDescriptionVisible() {
      return this.canView(this.permissionName, ['description']) && ((!this.exists && this.canAdd(this.permissionName, ['description']))
      || this.exists)
    },
    isDescriptionReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['description']))
    },
    isPermissionVisible() {
      return this.canView('PERMISSION')
    },
    disableOk() {
      return (this.original.readOnly && this.accessPolicy.readOnly) || this.state.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.$validator.resume();
        this.state.modalShow = newValue;
        this.alertMsg = null;
        this.alertMsgDetails = [];
        this.resetAccessPolicyProperties();
        this.state.editable = (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        this.userConsentOnRevokingCriticalPermission = false;
        this.restrictedRequiredField = null;
        if(this.exists) {
          this.accessPolicyGet(this.id);
          if (this.canView('USER')) {
            this.usersWithPolicyGet(this.id);
          }
        } else {
          if (newValue) { 
            const requiredFields = ['name']
            const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
            if (requiredCustomFields.length > 0) {
              requiredFields.push(...requiredCustomFields);
            }
            let result = this.canView2(this.permissionName, requiredFields);
            if (result.val) {
              result = this.canAdd2(this.permissionName, requiredFields)
            } 

            if (result.val && !this.canView('PERMISSION')) {
              result = { val: false, restrictedProp: 'PERMISSION' }
            }
            
            if (result.restrictedProp != null) {
              this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
            }

            if (result.val) {
              this.isAccessDenied = false;
            } else {
              this.isAccessDenied = true;
            }
              
          } else {
            this.isAccessDenied = false;
          }
          if (this.permissions) {
            this.originPermissions = cloneDeep(this.permissions);
            this.selectedPermissions.splice(0, this.selectedPermissions.length, ...cloneDeep(this.permissions));
          }
        }
      }
    }
  },
  methods: {
    getDisplayNameOfProperty(val) {
      if (val == 'PERMISSION') {
        return this.$t('permission.permission');
      } else {
        const found = this.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`access_policy.field.${val}`);
      }
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "ACCESS_POLICY"}).then(value => {
        self.modelInfo = value.ACCESS_POLICY.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    accessPolicyGet(id) {
      accessPolicyService.get([{ uuId: id}], ['PERMISSION'])
      .then(response => {
        const data = response.data[response.data.jobCase] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      }).catch(e => {
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      const s = this.accessPolicy;
      for (const key of Object.keys(s)) {
        s[key] = data[key] || null;
      }
      this.original.readOnly = data.readOnly;
      this.originPermissions = data.permissionList || [];
      this.selectedPermissions.splice(0, this.selectedPermissions.length, ...cloneDeep(this.originPermissions));

      this.originAccessPolicy = cloneDeep(s);
    },
    usersWithPolicyGet(id) {
      const self = this;
      this.userPolicy = {};
      userService.getByPolicy(id).then(response => {
        const data = response.data || [];
        for (var i = 0; i < data.length; i++) {
          const userId = data[i]['userId'];
          const permissionId = data[i]['permissionId'];
          const denyRules = data[i]['denyRules'];
          const dataRules = data[i]['dataRules'];
          if (!(userId in self.userPolicy)) {
            self.userPolicy[userId] = [];
          }
          self.userPolicy[userId].push({ permissionId, denyRules, dataRules });
        }
      }).catch(e => {
        this.httpAjaxError(e);
      });
    },
    modalOk() {
      this.errors.clear();
      // const self = this;
      this.$validator.validate().then(valid => {
        if (this.selectedPermissions.length < 1) {
          this.alertMsg = this.$t('permission.error.at_least_one');
          this.scrollToTop();
        } else if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.alertMsgDetails = [];

          if (!this.userConsentOnRevokingCriticalPermission && this.exists) {
            const userId = this.$store.state.authentication.user.uuId;
            if (userId != null && Object.hasOwn(this.userPolicy, userId)) {
              const criticalPermissions = ['USER__VIEW', 'USER__EDIT', 'ADMIN__APPLICATION_MANAGEMENT'];
              const perms = this.selectedPermissions.filter(i => criticalPermissions.includes(i.name));
              if (criticalPermissions.length > perms.length) {
                this.confirmRevokingCriticalPermissionShow = true;
                return;
              }
            }
          }
          if (Object.keys(this.userPolicy).length > 0) {
            this.confirmUpdateUserPermissionsShow = true;
          } else {
            this.accessPolicySubmit();
          }
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    accessPolicySubmit() {
      const data = cloneDeep(this.accessPolicy);

      let mode = 'update';
      if(!this.exists) {
        delete data['uuId'];
        mode = 'create';
        this.originPermissions = [];
      }
      this.accessPolicyPost(mode, data);
    },
    async accessPolicyPost(method, data) {
      this.state.isSubmitting = true;
      let accessPolicyId = null;
      let result = null;

      //Skip updating access policy if there is no change in access policy properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedAccessPolicyProperties(data);
      }

      if (method == 'create' || hasChanged) {
        removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
        result = await this.updateAccessPolicy(method, data);
        if(result.hasError) {
          this.alertMsg = result.msg;
          this.state.isSubmitting = false;
          return;
        }
        accessPolicyId = result.accessPolicyId;
      } else {
        accessPolicyId = data.uuId;
      }
      
      const alertDetails = [];

      result = await this.updatePermissions(accessPolicyId);
      if(result.hasError) {
        if(Array.isArray(result.msg)) {
          result.msg.forEach(i => {
            alertDetails.push(i);
          });
        } else {
          alertDetails.push(result.msg);
        }
      }

      if (this.userPermissionsNeedUpdate) {
        result = await this.updateUserPermissions(result);
        if(result.hasError) {
          if(Array.isArray(result.msg)) {
            result.msg.forEach(i => {
              alertDetails.push(i);
            });
          } else {
            alertDetails.push(result.msg);
          }
        } else {
          //Refresh the user details kept in the store
          await userService.getDetails()
          .then(response => response.data)
          .then(user => {
              this.$store.commit("authentication/updateUser", user);
          });
        }
      }

      this.state.isSubmitting = false;
      if(alertDetails.length > 0) {
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length, ...alertDetails);
        this.alertMsg = this.$t(`access_policy.${method}_partial`);
        this.scrollToTop();
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`access_policy.${method}`) });
      }
    },
    async updateAccessPolicy(method, data) {
      const result = {
        hasError: false,
        msg: this.$t(`access_policy.${method}`)
      }
      const self = this;
      let accessPolicyId = await accessPolicyService[method]([data])
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      }).catch(e => {
        result.hasError = true;
        result.msg = this.$t(`access_policy.error.failed_to_${method}_access_policy`);
        if(e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          let hasFieldError = false;
          const clues = ['missing_argument', 'invalid_value', 'column_limit_exceeded']
          for(let i = 0, len = list.length; i < len; i++) {
            if(clues.includes(list[i].clue)) {
              self.errors.add({
                field: `access_policy.${list[i].spot}`,
                msg: this.$t(`error.${list[i].clue}`, list[i].args? list[i].args : [this.$t(`access_policy.field.${list[i].spot}`)])
              });
              hasFieldError = true;
            }
          }
          if(hasFieldError) {
            result.msg = this.$t(`error.attention_required`);
          }
        }
      });
      result.accessPolicyId = accessPolicyId;
      return result;
    },
    async updatePermissions(accessPolicyId) {
      const result = {
        hasError: false,
        msg: this.$t('access_policy.update_permissions')
      }
      const errors = [];
      const perms = cloneDeep(this.selectedPermissions);
      const originPerms = this.originPermissions;
      const permToAdd = [];
      const permToUpdate = [];
      const permToRemove = [];

      // return the changes in the result
      result.permToAdd = permToAdd;
      result.permToUpdate = permToUpdate;
      result.permToRemove = permToRemove;
      
      perms.forEach(i => {
        const index = originPerms.findIndex(j => j.uuId === i.uuId);
        if(index === -1) {
          permToAdd.push(i);
        }
        else {
          const canDataRules = i.name.startsWith('CUSTOMER') ||
                               i.name.startsWith('DEPARTMENT') ||
                               i.name.startsWith('PROJECT') ||
                               i.name.startsWith('STAFF') ||
                               i.name.startsWith('TAG') ||
                               i.name.startsWith('STAGE');
          if (!canDataRules) {
            delete i.dataRules;
          }
          
          const originDeny = originPerms[index].permissionLink && originPerms[index].permissionLink.denyRules ? originPerms[index].permissionLink.denyRules : [];
          const originData = originPerms[index].permissionLink && originPerms[index].permissionLink.dataRules ? originPerms[index].permissionLink.dataRules : [];
          if ((originDeny.length === 0 && i.denyRules && i.denyRules.length > 0) ||
              (originData.length === 0 && i.dataRules && i.dataRules.length > 0) ||
              (originDeny.length !== 0 && !i.denyRules) ||
              (originData.length !== 0 && !i.dataRules) ||
              (i.denyRules && 
              (i.denyRules.filter(r => originDeny.findIndex(o => o === r) !== -1).length !== originDeny.length || // check for removed rules
              i.denyRules.length !== originDeny.length)) ||
              (i.dataRules && 
              (i.dataRules.filter(r => originData.findIndex(o => o === r) !== -1).length !== originData.length || // check for removed rules
              i.dataRules.length !== originData.length))) { // check for new rules
              permToUpdate.push(i);
          }
        }
      });

      originPerms.forEach(i => {
        if(perms.findIndex(j => j.uuId === i.uuId) == -1) {
          permToRemove.push(i);
        }
      });
      
      //Try to add link of permission to access policy
      if(permToAdd.length > 0) {
        let { failed, hasError, errorMsg } = await accessPolicyLinkPermissionService.create(accessPolicyId, permToAdd.map(i => { 
          
          const canDataRules = i.name.startsWith('CUSTOMER') ||
                               i.name.startsWith('DEPARTMENT') ||
                               i.name.startsWith('PROJECT') ||
                               i.name.startsWith('STAFF') ||
                               i.name.startsWith('TAG') ||
                               i.name.startsWith('STAGE');
          if (!canDataRules) {
            delete i.dataRules;
          }
          
          if (i.denyRules &&
              (i.name.endsWith('ADD') || !canDataRules)) {
            return { 
              uuId: i.uuId, 
              name: i.name, 
              permissionLink: { 
                denyRules: i.denyRules ? i.denyRules : []
              } 
            }
          }
          else if (i.denyRules ||
              i.dataRules) {
            return { 
              uuId: i.uuId, 
              name: i.name, 
              permissionLink: { 
                denyRules: i.denyRules ? i.denyRules : [],
                dataRules: i.dataRules ? i.dataRules : []
              } 
            }
          }
          return { 
            uuId: i.uuId, 
            name: i.name
          }
        }))
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            const failperms = permToAdd.filter(i => failIds.includes(i.uuId));
            return { failed: failperms };
          }
          return { failed: [] }
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge').map(i => i.args[0]);
            return { failed: permToAdd.filter(i => failIds.includes(i.uuId)) };
          } else {
            return { hasError: true, errorMsg: this.$t('access_policy.error.failed_to_update_permissions')}
          }
        });
        if(hasError) {
          result.hasError = true;
          result.msg = errorMsg;
          return result;
        }
        if(failed && failed.length > 0) {
          for(let i = 0, len = failed.length; i < len; i++) {
            errors.push(this.$t('access_policy.error.failed_to_add_permission', [failed[i].name]));
          }
        }
      }

      //Try to update link of permission to access policy
      if(permToUpdate.length > 0) {
        let { failed, hasError, errorMsg } = await accessPolicyLinkPermissionService.update(accessPolicyId, permToUpdate.map(i => 
        { 
          if (i.denyRules !== null &&
              i.name.endsWith('ADD')) {
            return { 
              uuId: i.uuId, 
              name: i.name, 
              permissionLink: { 
                denyRules: Array.isArray(i.denyRules)? i.denyRules : []
              } 
            }
          }
          else if (i.denyRules !== null ||
              i.dataRules !== null) {
            return { 
              uuId: i.uuId, 
              name: i.name, 
              permissionLink: { 
                denyRules: Array.isArray(i.denyRules)? i.denyRules : [],
                dataRules: Array.isArray(i.dataRules) ? i.dataRules : []
              } 
            }
          }
          else {
            const index = originPerms.findIndex(j => j.uuId === i.uuId);
            if(index !== -1) {
              const originDeny = originPerms[index].permissionLink ? originPerms[index].permissionLink.denyRules : [];
              const originData = originPerms[index].permissionLink ? originPerms[index].permissionLink.dataRules : [];
              if (originDeny.length !== 0 &&
                  i.name.endsWith('ADD')) {
                return {
                  uuId: i.uuId,
                  name: i.name,
                  permissionLink: {
                    denyRules: []
                  }
                }
              }
              else if (originDeny.length !== 0 ||
                  originData.length !== 0) {
                return {
                  uuId: i.uuId,
                  name: i.name,
                  permissionLink: {
                    denyRules: [],
                    dataRules: []
                  }
                }
              }
            }
          }
          
          return { 
            uuId: i.uuId, 
            name: i.name
          }
        }))
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            const failperms = permToUpdate.filter(i => failIds.includes(i.uuId));
            return { failed: failperms };
          }
          return { failed: [] }
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge').map(i => i.args[0]);
            return { failed: permToAdd.filter(i => failIds.includes(i.uuId)) };
          } else {
            return { hasError: true, errorMsg: this.$t('user.error.failed_to_update_permissions')}
          }
        });
        if(hasError) {
          result.hasError = true;
          result.msg = errorMsg;
          return result;
        }
        if(failed && failed.length > 0) {
          for(let i = 0, len = failed.length; i < len; i++) {
            errors.push(this.$t('user.error.failed_to_add_permission', [failed[i].name]));
          }
        }
      }
      
      //Remove link of permission to access policy
      if(permToRemove.length > 0) {
        const clues = ['OK', 'Unknown_relation'];
        let { failed, hasError, errorMsg } = await accessPolicyLinkPermissionService.remove(accessPolicyId, permToRemove.map(i => i.uuId))
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
            return { failed: permToRemove.filter(i => failIds.includes(i.uuId)) };
          }
          return { failed: [] };
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
            return { failed: permToRemove.filter(i => failIds.includes(i.uuId)) };
          } else {
            return { hasError: true, errorMsg: this.$t('access_policy.error.failed_to_update_permissions')}
          }
        });
        if(hasError) {
          result.hasError = true;
          result.msg = errorMsg;
          return result;
        }
        if(failed && failed.length > 0) {
          for(let i = 0, len = failed.length; i < len; i++) {
            errors.push(this.$t('access_policy.error.failed_to_delete_permission', [failed[i].name]));
          }
        }
      }

      if(errors.length > 0) {
        result.hasError = true;
        result.msg = errors;
      }
      return result;
    },
    async updateUserPermissions(changes) {
      const self = this;
      const result = {
        hasError: false,
        msg: this.$t('access_policy.update_user_permissions')
      }

      const errors = [];
      const perms = cloneDeep(this.selectedPermissions);

      const permToAdd = [];
      const permToUpdate = [];
      const permToRemove = [];
      
      // Build the add/remove list for each user using this policy
      Object.keys(this.userPolicy).forEach(key => {
        var userId = key;
        var originPerms = this.userPolicy[key];
        
        const userPermToAdd = [];
        const userPermToUpdate = [];
        const userPermToRemove = [];

        perms.forEach(i => {
          if (!this.updateChanges ||
              changes.permToUpdate.find(p => p.uuId === i.uuId)) {
            // Permissions from the server have the deny rules in i.permissionLink.denyRules
            // Edited permissions are in i.denyRules
            // copy the server permissions so that they are evaluated
            if (i.permissionLink &&
                i.permissionLink.denyRules &&
                !i.denyRules) {
              i.denyRules = i.permissionLink.denyRules;  
            }
            
            // Permissions from the server have the data rules in i.permissionLink.dataRules
            // Edited permissions are in i.dataRules
            // copy the server permissions so that they are evaluated
            if (i.permissionLink &&
                i.permissionLink.dataRules &&
                !i.dataRules) {
              i.dataRules = i.permissionLink.dataRules;  
            }
            
            const index = originPerms.findIndex(j => j.permissionId === i.uuId);
            if(index == -1) {
              userPermToAdd.push(i.denyRules && i.name.endsWith('ADD') ? { 
                uuId: i.uuId, 
                name: i.name, 
                permissionLink: { 
                  denyRules: i.denyRules ? i.denyRules : []
                } 
              } : i.denyRules ? { 
                uuId: i.uuId, 
                name: i.name, 
                permissionLink: { 
                  denyRules: i.denyRules ? i.denyRules : [],
                  dataRules: i.dataRules ? i.dataRules : []
                } 
              } : 
              { 
                uuId: i.uuId, 
                name: i.name
              });
            }
            else {
              const originDeny = originPerms[index].denyRules;
              const originData = originPerms[index].dataRules ? originPerms[index].dataRules : [];
              const originPermission = self.originPermissions.find(f => f.uuId === originPerms[index].permissionId);
              // get the differences between properties in the original policy and the new one
              const differences = this.getDifferences(originPermission.permissionLink && originPermission.permissionLink.denyRules ? originPermission.permissionLink.denyRules : [], i.denyRules);
              // update the denyRules with the changes
              if (this.updateChanges) {
                originDeny.push(...differences.added);
                for (const r of differences.removed) {
                  const idx = originDeny.indexOf(r);
                  if (idx !== -1) {
                    originDeny.splice(idx, 1);
                  }
                }
              }
              
              if ((originDeny.length === 0 && i.denyRules && i.denyRules.length > 0) ||
                (originData.length === 0 && i.dataRules && i.dataRules.length > 0) ||
                (originDeny.length !== 0 && !i.denyRules) ||
                (originData.length !== 0 && !i.dataRules) ||
                (i.denyRules && 
                (i.denyRules.filter(r => originDeny.findIndex(o => o === r) !== -1).length !== originDeny.length || // check for removed rules
                i.denyRules.length !== originDeny.length)) ||
                (i.dataRules && 
                (i.dataRules.filter(r => originData.findIndex(o => o === r) !== -1).length !== originData.length || // check for removed rules
                i.dataRules.length !== originData.length))) { // check for new rules
                userPermToUpdate.push(i.denyRules && i.name.endsWith('ADD') ? { 
                  uuId: i.uuId, 
                  name: i.name, 
                  permissionLink: { 
                    denyRules: i.denyRules ? i.denyRules : []
                  } 
                } :
                i.denyRules || i.dataRules ? { 
                  uuId: i.uuId, 
                  name: i.name, 
                  permissionLink: { 
                    denyRules: this.updateChanges ? originDeny : i.denyRules ? i.denyRules : [],
                    dataRules: this.updateChanges && originData.length !== 0 ? originData : i.dataRules ? i.dataRules : []
                  } 
                } :
                { 
                  uuId: i.uuId, 
                  name: i.name
                });
              }
            }
          }
        });

        originPerms.forEach(i => {
          if(perms.findIndex(j => j.uuId === i.permissionId) == -1) {
            userPermToRemove.push({uuId: i.permissionId});
          }
        });

        if (userPermToAdd.length > 0) {
          permToAdd.push({'uuId': userId, 'permissionList': userPermToAdd});
        }
        if (userPermToUpdate.length > 0) {
          permToUpdate.push({'uuId': userId, 'permissionList': userPermToUpdate});
        }
        if (userPermToRemove.length > 0) {
          permToRemove.push({'uuId': userId, 'permissionList': userPermToRemove});
        }
      });
      
      // Add all new links for all users
      if(permToAdd.length > 0) {
        let { failed, hasError, errorMsg } = await userService.addPermission(permToAdd)
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            const failperms = permToAdd.filter(i => failIds.includes(i.uuId));
            return { failed: failperms };
          }
          return { failed: [] }
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge').map(i => i.args[0]);
            return { failed: permToAdd.filter(i => failIds.includes(i.uuId)) };
          } else {
            return { hasError: true, errorMsg: this.$t('user.error.failed_to_update_permissions')}
          }
        });
        if(hasError) {
          result.hasError = true;
          result.msg = errorMsg;
          return result;
        }
        if(failed && failed.length > 0) {
          for(let i = 0, len = failed.length; i < len; i++) {
            errors.push(this.$t('user.error.failed_to_add_permission', [failed[i].name]));
          }
        }
      }

      // Update all new links for all users
      if(permToUpdate.length > 0) {
        let { failed, hasError, errorMsg } = await userService.updatePermission(permToUpdate)
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            const failperms = permToAdd.filter(i => failIds.includes(i.uuId));
            return { failed: failperms };
          }
          return { failed: [] }
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge').map(i => i.args[0]);
            return { failed: permToAdd.filter(i => failIds.includes(i.uuId)) };
          } else {
            return { hasError: true, errorMsg: this.$t('user.error.failed_to_update_permissions')}
          }
        });
        if(hasError) {
          result.hasError = true;
          result.msg = errorMsg;
          return result;
        }
        if(failed && failed.length > 0) {
          for(let i = 0, len = failed.length; i < len; i++) {
            errors.push(this.$t('user.error.failed_to_update_permissions', [failed[i].name]));
          }
        }
      }

      // Remove all old links from all users
      if(permToRemove.length > 0) {
        const clues = ['OK', 'Unknown_relation'];
        let { failed, hasError, errorMsg } = await userService.removePermission(permToRemove)
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
            return { failed: permToRemove.filter(i => failIds.includes(i.uuId)) };
          }
          return { failed: [] };
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
            return { failed: permToRemove.filter(i => failIds.includes(i.uuId)) };
          } else {
            return { hasError: true, errorMsg: this.$t('user.error.failed_to_update_permissions')}
          }
        });
        if(hasError) {
          result.hasError = true;
          result.msg = errorMsg;
          return result;
        }
        if(failed && failed.length > 0) {
          for(let i = 0, len = failed.length; i < len; i++) {
            errors.push(this.$t('user.error.failed_to_delete_permission', [failed[i].name]));
          }
        }
      }

      if(errors.length > 0) {
        result.hasError = true;
        result.msg = errors;
      }
      return result;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.errors.add({
            field: `access_policy.${feedback.spot}`,
            msg: this.$t(`error.${feedback.clue}`, feedback.args)
          })
        } else {
          this.alertMsg = this.$t('error.internal_server');
        }
      } else {
        this.alertMsg = this.$t('error.internal_server');
      }
      console.log(e) // eslint-disable-line no-console
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    getDifferences(origin, changed) {
      const added = [];
      const removed = [];
      for (const prop of origin) {
        if (changed.indexOf(prop) === -1) {
          removed.push(prop);
        }
      }
      for (const prop of changed) {
        if (origin.indexOf(prop) === -1) {
          added.push(prop);
        }
      }
      return { added, removed };
    },
    dismissAlert() {
      this.alertMsg = null;
      this.alertMsgDetails = [];
    },
    resetAccessPolicyProperties() {
      const keys = Object.keys(this.accessPolicy);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId') {
          continue;
        }
        this.accessPolicy[keys[i]] = null;
      }
      this.originPermissions = [];
      this.selectedPermissions = [];
      this.userPolicy =  {};
      this.userPermissionsNeedUpdate = false;
      this.originAccessPolicy = null;
    },
    permissionSelected(ids) {
      this.selectedPermissions.splice(0, this.selectedPermissions.length, ...ids);
    },
    confirmUpdateUserPermissions() {
      this.userPermissionsNeedUpdate = true;
      this.accessPolicySubmit();
    },
    removeUnchangedAccessPolicyProperties(data) {
      //Remove those properties whose value is not changed in provided data against original access policy.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalAccessPolicy = this.originAccessPolicy;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalAccessPolicy[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    confirmRevokingCriticalPermissionOk() {
      this.userConsentOnRevokingCriticalPermission = true;
      this.modalOk();
    },
  }
}
</script>
